:root {
  --greyscale600: #1d1d1b;
  --greyscale500: #525251;
  --white: #ffffff;
  --orange600: #ff991f;
}

body {
  height: 100%;
}

html {
  height: 100%;
  overflow-y: 'scroll';
}

div#app,
body > #__next {
  height: 100%;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* increase spacing in outline */
*:focus {
  outline-offset: 2px;
}

/* reset the unwated resets (in order to not modify the original reset file) */

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0;
}

ol,
ul {
  list-style: none;
}

button {
  background-color: transparent;
  background-image: none;
  padding: 0;
}
hr {
  border-top-width: 1px;
}
/**
   * Work around a Firefox/IE bug where the transparent `button` background
   * results in a loss of the default `button` focus styles.
   */

button:focus-visible {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

button,
[role='button'] {
  cursor: pointer;
}

a {
  color: var(--greyscale600);
}

label {
  line-height: 1.8;
}

h3 {
  margin-bottom: 20px;
}

img {
  display: inline-block;
  vertical-align: middle;
}

/* to remove input type="number" arrow  */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Common */
.base-transition {
  transition: all 0.25s ease-in-out;
}

/* Hamburger */
button.hamburger {
  padding: 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 21px;
  display: inline-block;
  position: relative;
  margin-top: 8px;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  width: 24px;
  height: 3px;
  background-color: #345;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner:after,
.hamburger-inner:before {
  content: '';
  display: block;
}

.hamburger-inner:before {
  top: -9px;
}

.hamburger-inner:after {
  bottom: -9px;
}

.squeeze .hamburger-inner {
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.squeeze .hamburger-inner:before {
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}

.squeeze .hamburger-inner:after {
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  background-color: #345;
}

.squeeze.is-active .hamburger-inner:before {
  background-color: #fff;
  top: 0;
  opacity: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
}

.squeeze.is-active .hamburger-inner:after {
  background-color: #345;
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}

.mobile-menu-open {
  overflow: hidden;
}

.fixed-body {
  overflow: hidden;
}

/* Hide zEWidget on mobile */
@media only screen and (max-width: 768px) {
  .zEWidget-launcher {
    display: none;
  }
}

input[type='email'],
input[type='number'],
input[type='tel'],
input[type='url'],
input[type='password'],
input[type='text'] {
  height: 48px;
  outline: 0;
}

select {
  height: 60px;
}

[type='color'],
[type='date'],
[type='datetime-local'],
[type='datetime'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
textarea {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--greyscale600);
  -webkit-transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  transition: border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  -o-transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out, -webkit-box-shadow 0.5s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.itoggle {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  position: absolute;
}
input.itoggle + label {
  display: block;
  position: relative;
  box-shadow: inset 0 0 0 1px #d5d5d5;
  height: 30px;
  width: 50px;
  border-radius: 15px;
  margin-bottom: 0;
}
input.itoggle + label:before {
  content: '';
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  top: 0;
  left: 0;
  border-radius: 15px;
  background: rgba(19, 191, 17, 0);
  transition: 0.25s ease-in-out;
}
input.itoggle + label:after {
  content: '';
  position: absolute;
  display: block;
  height: 30px;
  width: 30px;
  top: 0;
  left: 0;
  border-radius: 15px;
  background: white;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: 0.25s ease-in-out;
}
input.itoggle:checked + label:before {
  width: 50px;
  background: #0085cd;
  content: '';
}
input.itoggle:checked + label:after {
  left: 20px;
  box-shadow: inset 0 0 0 1px #0085cd, 0 2px 4px rgba(65, 183, 241, 0.2);
}
input.itoggle:disabled + label {
  opacity: 0.5;
}

img,
a {
  border: 0;
}

.suggestion-item,
.suggestion-item--active {
  padding: 5px 20px;
  cursor: pointer;
  color: #666;
  text-align: center;
}

.suggestion-item > strong,
.suggestion-item--active > strong {
  font-size: 16px;
  display: block;
}

.suggestion-item > small .suggestion-item--active > small {
  font-size: 12px;
  display: block;
}

/* Image Gallery */
.image-gallery-bullets .image-gallery-bullet {
  background-color: var(--white);
  opacity: 0.5;
  box-shadow: none;
  border: 0;
}

.image-gallery-bullets .image-gallery-bullet.active {
  opacity: 1;
}

.image-gallery-bullets {
  bottom: 16px;
  z-index: 1;
}

.image-gallery-index {
  position: absolute;
  right: 8px;
  bottom: 8px;
  border-radius: 3px;
  top: auto;
  text-align: right;
  font-size: 11px;
  background-color: var(--greyscale600);
}

.image-gallery-slide img {
  width: 100%;
  object-fit: cover;
}

@media only screen and (min-width: 1024px) {
  .image-gallery-index {
    padding: 14px 0 16px 0;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 100%;
    top: auto;
    text-align: center;
    color: var(--greyscale500);
    background: none;
    font-size: 13px;
  }

  .image-gallery-swipe {
    max-width: 1186px;
    margin: 0 auto;
  }

  .image-gallery-slides {
    overflow: hidden;
  }

  .is-on-map .image-gallery-slides {
    border-bottom-left-radius: 0;
  }
}

@keyframes scale {
  0% {
    transform: scale(1);
  }
  45% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.95);
  }
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
  100% {
    transform: translateY(0);
  }
}

.grecaptcha-badge {
  display: none !important;
}

/* Ensure all children of contentLayout are 100% width */

.layout-inner > * {
  width: 100%;
}

/* Phone number prefix select */
.phone-prefix-select {
  display: block;
}

.phone-prefix-select div[role='option'] {
  padding: 8px;
}

.phone-prefix-select span {
  text-align: center;
}

.poi-filter-button:hover path {
  fill: var(--greyscale600);
}

.read-more-toggle {
  cursor: pointer;
}

.read-more-toggle details[open] > summary {
  display: none;
}

.read-more-toggle details > summary {
  list-style: none;
}

.read-more-toggle details > summary::-webkit-details-marker {
  display: none;
}

/* this class is used in some unit tests */
.dummy-test-class {
  height: 25px;
  width: 25px;
}

.mobile-contact-us__button-wrapper button {
  flex: 1;
  fontweight: 600;
}

.mobile-map-button > * {
  flex-shrink: 0;
}

.city-link:hover > .listing-cities-icon {
  transform: translateX(2px);
}
